import { App, defineAsyncComponent } from 'vue'

export const registerComponents = (app: App) => {
  const names = [
    'v-app-loader',
    'v-code-input',
    'v-game-intro',
    'v-icon',
    'v-loader',
    'v-load-button',
    'v-min-max-input',
    'v-promise-button',
    'v-results-shared',
    'v-scores',
    'v-timer-ring',
    'v-wordcloud',
    'v-modal',
  ]

  names.forEach((name) => {
    app.component(
      name,
      defineAsyncComponent(() => import(`../components/${name}/index.vue`)),
    )
  })
}
