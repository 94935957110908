/**
 * Teamup
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime"
import * as QS from "oazapfts/lib/runtime/query"
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "http://incontext-teamup.test/api",
}
const oazapfts = Oazapfts.runtime(defaults)
export const servers = {
    server1: "http://incontext-teamup.test/api"
}
export type ParticipantStatus = "active" | "disabled";
export type RoomStatus = "pending" | "questionnaire" | "games" | "finished" | "archived";
export type FeedbackGameStatus = "intro" | "playing" | "processing" | "results" | "finished" | "ready" | "discontinued";
export type BattleshipGameStatus = "intro" | "setup-participant" | "setup-opponent" | "play-participant" | "result-participant" | "play-opponent" | "results" | "finished";
export type BattleshipGameTheme = "battleship" | "treasurehunt";
export type BattleshipGame = {
    hash: string;
    status: BattleshipGameStatus;
    level?: "easy" | "medium" | "hard";
    theme?: BattleshipGameTheme;
    nextStatusIn: any;
    gridSize: number;
    requiredHitsForWin: any;
    activeRound?: {
        sequence?: number;
        id?: number;
    };
};
export type BattleshipParticipantGameStatus = "playing" | "won" | "defeated";
export type BattleshipParticipant = {
    status: BattleshipParticipantGameStatus;
    name?: string;
    hits?: {
        self: number;
        opponent: string;
    };
    hash: string;
};
export type BattleshipShip = {
    "class": string;
    position: number[][];
};
export type BattleshipShot = {
    hit: boolean;
    executed: boolean;
    position: number[];
};
export type BattleshipParticipantGame = {
    hash: string;
    status: BattleshipGameStatus;
    theme: BattleshipGameTheme;
    gridSize: number;
    requiredHitsForWin: number;
    participant: BattleshipParticipant;
    currentRound: {
        id: number;
        sequence: number;
    };
    fleet?: {
        participant?: BattleshipShip[];
        opponent?: {
            "class": string;
            position: number[][];
        }[];
    };
    shots?: {
        participant?: BattleshipShot[];
        opponent?: {
            hit: boolean;
            executed: boolean;
            position: number[];
        }[];
    };
    hints?: {
        id: number;
        level: number;
        participant: string;
        message: string;
    }[];
};
export type BattleshipRoundDifficulty = "easy" | "standard" | "difficult";
export type Room = {
    hash: string;
    code: string;
    locale?: string;
    name: string;
    organization?: string;
    status: RoomStatus;
    isPaused?: boolean;
    score: number;
    startOption?: "battleship" | "questionnaire-battleship" | "questionnaire-feedback";
};
export type Participant = {
    hash: string;
    name: string;
    finishedQuestionnaire: boolean;
    status: ParticipantStatus;
    score: number;
    previousScore: number;
};
export type StageType = "feedback" | "battleship";
export type StageStatus = "playing" | "dashboard-start" | "dashboard-end";
export type FeedbackRoomGameStatus = "intro" | "playing" | "discontinued" | "processing" | "results" | "finished" | "ready";
export type PauseType = {
    value: "break" | "intervention" | "pink-elephant";
    description: string;
};
/**
 * submit a Participant's answer to the questionnaire
 */
export function submitQuestionnaireAnswers(body?: {
    answers?: {
        question: string;
        answer: string;
    }[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/questionnaire/answers", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Retrieve a token to authenticate with
 */
export function loginParticipant(body?: {
    room: string;
    name: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {
            token?: string;
        };
    }>("/participant/auth", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Retrieve a token to authenticate with
 */
export function loginFacilitator(body?: {
    room: string;
    password: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {
            token?: string;
        };
    }>("/facilitator/auth", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function listQuestionnaireQuestions(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                "type"?: "know-your-colleague" | "dilemma";
                title: string;
                option: {
                    hash: string;
                    title: string;
                    image?: any;
                }[];
            }[];
        };
    }>("/questionnaire/questions", {
        ...opts
    })
}
/**
 * Create a new participant for a room
 */
export function createParticipant(body?: {
    room: string;
    name: string;
    email?: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: {
            token: string;
            participant: {
                hash: string;
                name: string;
                finishedQuestionnaire: boolean;
                status: ParticipantStatus;
                score: number;
                previousScore: number;
            };
        };
    } | {
        status: 422;
        data: {
            message?: string;
            errors?: {
                message?: string;
            }[];
        };
    }>("/participant", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Create a new room
 */
export function createRoom(signature: string, body?: {
    identifier: string;
    locale?: "nl" | "en";
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                code: string;
                locale?: string;
                name: string;
                organization?: string;
                status: RoomStatus;
                isPaused?: boolean;
                score: number;
                startOption?: "battleship" | "questionnaire-battleship" | "questionnaire-feedback";
            };
        };
    }>("/rooms", oazapfts.json({
        ...opts,
        method: "POST",
        body,
        headers: {
            ...opts && opts.headers,
            Signature: signature
        }
    }))
}
export function postFeedbackGameAnswer(game: string, body?: {
    answer?: string;
} | {
    answer?: string[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {};
    }>(`/feedback/games/${game}/answer`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getFeedbackGameDetails(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                "type": string;
                role: "subject" | "executive" | "jury";
                status: FeedbackGameStatus;
                selectsNextGame?: boolean;
                score?: number;
                time?: {
                    total: number;
                    remaining: number;
                };
                activeParticipant: {
                    hash: string;
                    name: string;
                };
                subject?: {
                    hash: string;
                    name: string;
                };
            };
        };
    }>(`/feedback/games/${game}`, {
        ...opts
    })
}
export function getBattleshipIntroduction(type: "first" | "other", opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            content: string;
        };
    }>(`/battleship/introduction${QS.query(QS.form({
        type
    }))}`, {
        ...opts
    })
}
export function createBattleshipGame(body?: {
    level?: "easy" | "medium" | "hard";
    theme?: string;
    roundDuration?: number;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/battleship/games", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getBattleshipGameDetails(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: BattleshipGame | BattleshipParticipantGame;
        };
    }>(`/battleship/games/${game}`, {
        ...opts
    })
}
export function patchBattleshipGame(game: string, body?: {
    action: "switch-stage-after-current-round" | "set-next-round-difficulty";
    difficulty?: BattleshipRoundDifficulty;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/battleship/games/${game}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getBattleshipDashboardSummary(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                awards?: object;
                charts?: object;
            };
        };
    }>(`/battleship/games/${game}/dashboards/summary`, {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getBattleshipDashboardFacilitatorSummary(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                charts?: object;
            };
        };
    }>(`/battleship/games/${game}/dashboards/facilitator/summary`, {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getBattleshipDashboardIncoming(game: string, participant: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                charts?: object;
            };
        };
    }>(`/battleship/games/${game}/dashboards/incoming/${participant}`, {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getBattleshipDashboardOutgoing(game: string, participant: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                charts?: object;
            };
        };
    }>(`/battleship/games/${game}/dashboards/outgoing/${participant}`, {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getBattleshipParticipantGameDetails(game: string, participant: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: {
                hash: string;
                status: BattleshipGameStatus;
                theme: BattleshipGameTheme;
                gridSize: number;
                requiredHitsForWin: number;
                participant: BattleshipParticipant;
                currentRound: {
                    id: number;
                    sequence: number;
                };
                fleet?: {
                    participant?: BattleshipShip[];
                    opponent?: {
                        "class": string;
                        position: number[][];
                    }[];
                };
                shots?: {
                    participant?: BattleshipShot[];
                    opponent?: {
                        hit: boolean;
                        executed: boolean;
                        position: number[];
                    }[];
                };
                hints?: {
                    id: number;
                    level: number;
                    participant: string;
                    message: string;
                }[];
            };
        };
    }>(`/battleship/games/${game}/participant/${participant}`, {
        ...opts
    })
}
export function listBattleshipGameParticipants(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: BattleshipParticipant[];
        };
    }>(`/battleship/games/${game}/participants`, {
        ...opts
    })
}
export function postBattleshipGameStatus(game: string, body?: {
    status: "next" | BattleshipGameStatus;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/battleship/games/${game}/status`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function getBattleshipGameResults(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            game: {
                hash: string;
                status: BattleshipGameStatus;
                level?: "easy" | "medium" | "hard";
                theme?: BattleshipGameTheme;
                nextStatusIn: any;
                gridSize: number;
                requiredHitsForWin: any;
                activeRound?: {
                    sequence?: number;
                    id?: number;
                };
            };
            self: {
                status: BattleshipParticipantGameStatus;
                name?: string;
                hits?: {
                    self: number;
                    opponent: string;
                };
                hash: string;
            };
            participant: BattleshipParticipant[];
            awards?: {
                sharer?: BattleshipParticipant;
                sniper?: BattleshipParticipant;
            };
        };
    }>(`/battleship/games/${game}/results`, {
        ...opts
    })
}
export function postBattleshipGameFleet(game: string, body?: {
    ships: {
        "class": string;
        position: number[][];
    }[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/battleship/games/${game}/fleet`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function postBattleshipShot(game: string, body?: {
    position: number[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/battleship/games/${game}/shots`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function postBattleshipHintsUsage(game: string, body?: {
    hintsShare: number;
    timePerHint: {
        hint: number;
        seconds: number;
    }[];
    currentRoundID?: number;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/battleship/games/${game}/hints-usage`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function listBattleshipGameLevels(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            key: string;
            label: string;
            defaultTurnDuration: number;
        }[];
    }>("/battleship/game-levels", {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getState(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data: {
                room?: Room;
                participant?: Participant;
                facilitator?: {
                    hash: string;
                };
                activeGameHash?: string | null;
                activeGameStatus?: FeedbackGameStatus | BattleshipGameStatus;
                stage: {
                    "type": StageType;
                    status: StageStatus;
                    sequence: number;
                };
                nextGameSelector?: Participant;
                activeLevels?: number[];
                availableLevels?: number[];
            };
        };
    }>("/state", {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getRoomDetails(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: Room & {
                participants?: Participant[];
                nextGameSelector?: Participant;
            };
        };
    }>("/room", {
        ...opts
    })
}
/**
 * Set room details (only for room-admin)
 */
export function patchRoomDetails(body?: {
    name: string;
    organization: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room", oazapfts.json({
        ...opts,
        method: "PATCH",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getFeedbackGamesSelect(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data: {
                hash: string;
                "type": {
                    "class": string;
                    description: string;
                };
                subjectID: number | null;
            }[];
        };
    }>("/feedback/games/select", {
        ...opts
    })
}
/**
 * Select next game
 */
export function postGamesSelect(body?: {
    game: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/feedback/games/select", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getRoomDetailsByCode(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: Room;
        };
    }>(`/rooms/${code}`, {
        ...opts
    })
}
/**
 * Your GET endpoint
 */
export function getParticipantsByRoom(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            data?: Participant[];
        };
    }>(`/rooms/${code}/participants`, {
        ...opts
    })
}
/**
 * Skip the participant that's currently appointed as 'active'. (This disables the participant session)
 */
export function skipCurrentActiveParticipant(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/feedback/games/select/skip", {
        ...opts,
        method: "POST"
    })
}
export function updateFeedbackGameStatus(game: string, body?: {
    status?: FeedbackRoomGameStatus;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/feedback/games/${game}/status`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getFeedbackGameResults(game: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            score: number;
            teamScore: number;
        };
    }>(`/feedback/games/${game}/results`, {
        ...opts
    })
}
export function startRoomPause(body?: {
    "type"?: PauseType;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room/pause", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function endRoomPause(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room/pause", {
        ...opts,
        method: "DELETE"
    })
}
/**
 * If room is paused, get pause details.
 */
export function getRoomPause(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {
            "type": PauseType;
            startedBy?: Participant | ({} | null);
        };
    }>("/room/pause", {
        ...opts
    })
}
export function setRoomStatus(body?: {
    status?: "finished" | "questionnaire";
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
        data: {
            data?: Room;
        };
    }>("/room/status", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function setParticipantStatus(participant: string, body?: {
    status?: "active" | "disabled";
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/participants/${participant}/status`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getSettings(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: object;
    }>("/settings", {
        ...opts
    })
}
export function createRoomStage(body?: {
    "type"?: StageType;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room/stages", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
export function updateRoomStageStatus(body?: {
    status?: StageStatus;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/room/stages/status", oazapfts.json({
        ...opts,
        method: "POST",
        body
    }))
}
/**
 * Your GET endpoint
 */
export function getRoomStagesResults(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: {};
    }>("/room/stages/results", {
        ...opts
    })
}
