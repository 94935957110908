import Echo from 'laravel-echo'
import { SocketIoChannel } from 'laravel-echo/dist/channel'
import io from 'socket.io-client/dist/socket.io.slim'
import { App } from 'vue'

let host = `//${location.host}/`
if (location.host.includes('.test')) {
  host = import.meta.env.VITE_BROADCAST_URL as string
}

const echo = new Echo({
  client: io,
  host,
  broadcaster: 'socket.io',
  withoutInterceptors: true,
})

export default {
  install(app: App) {
    app.config.globalProperties.$echo = echo
  },
}

export interface EchoInterface {
  channel(identfier: string): ChannelInterface
}

export interface ChannelInterface {
  socket: any
  listen(event: string, callback: (...args: any[]) => unknown): EchoInterface
  on(event: string, callback: (...args: any[]) => unknown): void
  bind(event: string, callback: (...args: any[]) => unknown): void
  stopListening(event: string): SocketIoChannel
}

export { echo }
