import { createI18n } from 'vue-i18n'

import messages from '/@front/locale/en.json'

const i18n = createI18n({
  locale: 'en',
  messages: {
    en: messages,
  } as any,
})

const loadedLocales = ['en']

const setLocale = (locale: string) => {
  i18n.global.locale = locale
  ;(document.querySelector('html') as HTMLElement).setAttribute('lang', locale)

  return locale
}

const changeLocale = (locale: string, customMessages: any = {}) => {
  if (i18n.global.locale === locale) {
    i18n.global.mergeLocaleMessage(locale, customMessages)
    return Promise.resolve(setLocale(locale))
  }

  if (loadedLocales.includes(locale)) {
    i18n.global.mergeLocaleMessage(locale, customMessages)
    return Promise.resolve(setLocale(locale))
  }

  const localeFilename = locale.includes('-')
    ? locale.split('-').shift()
    : locale

  return import(`../locale/${localeFilename}.json`).then((msgs) => {
    i18n.global.setLocaleMessage(locale, msgs.default)
    i18n.global.mergeLocaleMessage(locale, customMessages)
    loadedLocales.push(locale)

    return setLocale(locale)
  })
}

const t = i18n.global.t

export default i18n

export { changeLocale, i18n, t }
