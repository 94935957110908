import _merge from 'lodash.merge'
import store from 'store'
import { Options, Vue } from 'vue-class-component'

import vFlits from '/@front/components/v-flits/index.vue'
import api, { HttpError } from '/@front/shared/api'
import { tokenStorageKey } from '/@front/shared/constants'
import { getRouteForRoomStatus } from '/@front/shared/helpers'
import { State } from '/@front/shared/interfaces'

@Options({
  components: {
    'v-flits': vFlits,
  },
})
export default class App extends Vue {
  private state: State | null = {} as State
  private appIsReady = false
  private isLoading = true

  private get isPaused() {
    return this.state?.room?.isPaused || false
  }

  private get roomStatus() {
    return this.state?.room?.status
  }

  private checkStorageOnToken() {
    const token = store.get(tokenStorageKey)

    if (token) {
      this.setBearerToken(token)
      this.fetchState()
    } else {
      this.isLoading = false
    }
  }

  private monitorVisibilityEvents() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange, false)
    window.addEventListener('focus', this.handleVisibilityChange, false)
  }

  private monitorStateEvents() {
    this.$bus.$on('fetchState', this.fetchState)
    this.$bus.$on('updateState', this.updateState)
  }

  private monitorGlobalEvents() {
    this.$bus.$on('login', this.login)
  }

  private handleVisibilityChange() {
    setTimeout(() => {
      if (document.visibilityState !== 'hidden') {
        this.fetchState()
      }
    }, 300)
  }

  private login(token: string) {
    this.state = null
    this.setBearerToken(token)
    this.fetchState().then(() => {
      this.redirect()
    })
  }

  private setBearerToken(token: string) {
    store.set(tokenStorageKey, token)
    ;(api.defaults.headers as any).Authorization = `Bearer ${token}`
  }

  private async fetchState() {
    const token = store.get(tokenStorageKey)

    if (!token) {
      return
    }

    return api
      .getState()
      .then((response: any) => {
        const shouldUpdateState = JSON.stringify(response.data) !== JSON.stringify(this.state)

        if (shouldUpdateState) {
          this.updateState(response.data)
        }

        this.$bus.$emit('stateUpdated')
      })
      .catch((error: any) => {
        if (error instanceof HttpError) {
          throw error
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  private updateState(obj: any) {
    this.state = _merge(this.state, obj)
    window.$teamupState = this.state
  }

  private redirect() {
    const route = getRouteForRoomStatus(this.roomStatus)

    if (this.$route.name !== route) {
      this.$router.push({ name: route })
    }
  }

  public mounted() {
    this.checkStorageOnToken()
    this.monitorStateEvents()
    this.monitorGlobalEvents()
    this.monitorVisibilityEvents()

    setTimeout(() => {
      this.appIsReady = true
    }, 1800)
  }
}
