import { App } from 'vue'

import modal from '/@front/components/v-modal'
import echo from '/@front/plugins/echo'
import flits from '/@front/plugins/flits'
import { i18n } from '/@front/plugins/i18n'

export const registerPlugins = (app: App) => {
  app.use(echo)
  app.use(flits)
  app.use(i18n)
  app.use(modal)
}
