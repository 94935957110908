import { ApiResponse } from 'oazapfts/src/runtime'

import * as sdkRaw from '/@front/shared/sdk'

type DataType<T extends ApiResponse, S extends number> = T extends { status: S } ? T['data'] : never

const SUCCESS_CODES = [200, 201, 202, 204] as const
type SuccessCodes = typeof SUCCESS_CODES[number]

type SuccessResponse<T extends ApiResponse> = DataType<T, SuccessCodes>

type Args<T> = T extends (...args: infer U) => any ? U : any

type ApiFunction = (...args: any[]) => Promise<ApiResponse>

type AsyncReturnType<T> = T extends (...args: any[]) => Promise<infer V> ? V : never

type Okify<T extends ApiFunction> = (...args: Args<T>) => Promise<OkResponse<T>>

type OkResponse<T extends ApiFunction> = SuccessResponse<AsyncReturnType<T>>

type OptimisticApi<T> = {
  [K in keyof T]: T[K] extends ApiFunction ? Okify<T[K]> : T[K]
}

export class HttpError extends Error {
  status: number
  data?: any
  payload?: any
  constructor(status: number, data: any, payload: any) {
    super(`Error: ${status}`)
    this.status = status
    this.data = data
    this.payload = payload
  }
}

async function ok<T extends ApiResponse>(fn: ApiFunction, args: Args<ApiFunction>): Promise<SuccessResponse<T>> {
  const promise: Promise<ApiResponse> = fn(...args)

  const res = await promise
  if (SUCCESS_CODES.some((s) => s === res.status)) {
    return res.data
  }

  throw new HttpError(res.status, res.data, {
    function: fn.name,
    args,
  })
}

function okify<T extends ApiFunction>(fn: T): Okify<T> {
  return (...args: Args<T>) => {
    return ok(fn, args)
  }
}

function optimistic<T extends Record<string, ApiFunction | unknown>>(origApi: T): OptimisticApi<T> {
  const okApi: any = {}
  Object.entries(origApi).forEach(([key, value]) => {
    okApi[key] = typeof value === 'function' ? okify(value as any) : value
  })
  return okApi
}

const api = optimistic(sdkRaw)

api.defaults.baseUrl = `//${location.host}/api/`
api.defaults.headers = {
  Accept: 'application/json',
}

const wrapApiPromise = (callback: () => unknown) => {
  return new Promise(async (resolve, reject) => {
    try {
      await callback()
    } catch (error) {
      reject(error)
    }
  })
}

export default api

export { api, wrapApiPromise }
