import { Room } from './sdk'

export function empty(obj: any): boolean {
  return obj === null || obj === undefined || obj === '' || (Array.isArray(obj) && obj.length === 0)
}

export function randomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function randomSign() {
  return Math.random() < 0.5 ? -1 : 1
}

export function randomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export function randomHexColor() {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return '#' + n.slice(0, 6)
}

export function getChannelName(room: Room | undefined) {
  return import.meta.env.VITE_REDIS_PREFIX + (room as Room).hash
}

export function uniqueID() {
  /* eslint-disable */
  let start = (Math.random() * 46656) | 0
  let end = (Math.random() * 46656) | 0
  /* eslint-enable */
  return ('000' + start.toString(36)).slice(-3) + ('000' + end.toString(36)).slice(-3)
}

export function getTranslation(element: HTMLElement) {
  const transform = window.getComputedStyle(element).transform
  const matrix = new WebKitCSSMatrix(transform)

  return {
    x: matrix.m41,
    y: matrix.m42,
  }
}

export function caclulateBoundsForPosition(
  position: any,
  cellDimensions: { width: number; height: number },
  gridRectangle: DOMRect,
) {
  const rowStart = position[0][0]
  const rowEnd = position[1][0] + 1
  const colStart = position[0][1]
  const colEnd = position[1][1] + 1

  const height = rowEnd - rowStart
  const width = colEnd - colStart
  const top = rowStart - 1
  const left = colStart - 1

  return {
    minX: -left * cellDimensions.width,
    maxX: gridRectangle.width - (width + left) * cellDimensions.width,
    minY: -top * cellDimensions.height,
    maxY: gridRectangle.height - (height + top) * cellDimensions.height,
  }
}

let previousCellDimension: { width: number; height: number } | null = null

export const getFixedTransformPosition = (el: HTMLElement, cellDimension: { width: number; height: number }) => {
  // Extract current 3D transform added by draggable
  const [, transform] = el.style.transform.match(/\((.*)\)/) ?? []

  if (!transform) {
    return
  }

  // Remember previous cellDimension so we can correctly
  // calculate xCount and yCount
  if (!previousCellDimension) {
    previousCellDimension = cellDimension
  }

  const currentPosition = transform
    .split(',')
    .map((value) => value.trim())
    .map((value) => parseInt(value))

  const xCount = Math.round(currentPosition[0] / previousCellDimension.width)
  const yCount = Math.round(currentPosition[1] / previousCellDimension.height)

  // Update x,y value's to reflect new dimensions
  currentPosition[0] = xCount * cellDimension.width
  currentPosition[1] = yCount * cellDimension.height

  const translateProperties = currentPosition
    .map((position) => {
      return position !== 0 //
        ? position + 'px'
        : position
    })
    .join(', ')

  previousCellDimension = cellDimension

  // return updated transform value
  return `translate3D(${translateProperties})`
}

export function getRouteForRoomStatus(status: string | undefined) {
  const map: any = {
    pending: 'lobby',
    finished: 'room-finished',
    archived: 'room-finished',
    questionnaire: 'lobby',
    games: 'games',
  }

  return map[status as string]
}
