export default [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('/@front/views/app/auth/index.vue'),
    children: [
      {
        path: 'questionnaire',
        name: 'questionnaire',
        component: () => import('/@front/views/app/auth/questionnaire/index.vue'),
      },
      {
        path: 'lobby',
        name: 'lobby',
        component: () => import('/@front/views/app/auth/lobby/index.vue'),
      },
      {
        path: 'lobby/qr',
        name: 'qr',
        component: () => import('/@front/views/app/auth/qr/index.vue'),
      },
      {
        path: 'games',
        name: 'games',
        component: () => import('/@front/views/app/auth/games/index.vue'),
        children: [
          {
            path: 'feedback',
            name: 'feedback',
            component: () => import('/@front/views/app/auth/games/feedback/index.vue'),
            children: [
              {
                path: 'select',
                name: 'feedback-select',
                component: () => import('/@front/views/app/auth/games/feedback/select/index.vue'),
              },
              {
                path: ':hash',
                name: 'feedback-play',
                component: () => import('/@front/views/app/auth/games/feedback/play/index.vue'),
              },
              {
                path: 'leaderboard',
                name: 'feedback-leaderboard',
                component: () => import('/@front/views/app/auth/games/feedback/leaderboard/index.vue'),
              },
            ],
          },
          {
            path: 'battleship',
            name: 'battleship',
            component: () => import('/@front/views/app/auth/games/battleship/index.vue'),
            children: [
              {
                path: 'wait',
                name: 'battleship-wait',
                component: () => import('/@front/views/app/auth/games/battleship/wait/index.vue'),
              },
              {
                path: 'game',
                name: 'battleship-game',
                component: () => import('/@front/views/app/auth/games/battleship/game/index.vue'),
                children: [
                  {
                    path: 'intro',
                    name: 'battleship-intro',
                    component: () => import('/@front/views/app/auth/games/battleship/game/intro/index.vue'),
                  },
                  {
                    path: 'setup/participant',
                    name: 'battleship-setup-participant',
                    component: () => import('/@front/views/app/auth/games/battleship/game/setup/participant/index.vue'),
                  },
                  {
                    path: 'setup/opponent',
                    name: 'battleship-setup-opponent',
                    component: () => import('/@front/views/app/auth/games/battleship/game/setup/opponent/index.vue'),
                  },
                  {
                    path: 'play/participant',
                    name: 'battleship-play-participant',
                    redirect: () => '/auth/games/battleship/game/play/participant/board',
                    component: () => import('/@front/views/app/auth/games/battleship/game/play/participant/index.vue'),
                    children: [
                      {
                        path: 'board',
                        name: 'battleship-play-participant-board',
                        component: () =>
                          import('/@front/views/app/auth/games/battleship/game/play/participant/board/index.vue'),
                      },
                      {
                        path: 'hints',
                        name: 'battleship-play-participant-hints',
                        component: () =>
                          import('/@front/views/app/auth/games/battleship/game/play/participant/hints/index.vue'),
                      },
                    ],
                  },
                  {
                    path: 'play/result',
                    name: 'battleship-result-participant',
                    component: () => import('/@front/views/app/auth/games/battleship/game/play/result/index.vue'),
                  },
                  {
                    path: 'play/opponent',
                    name: 'battleship-play-opponent',
                    component: () => import('/@front/views/app/auth/games/battleship/game/play/opponent/index.vue'),
                  },
                  {
                    path: 'dashboard/participant',
                    name: 'battleship-dashboard-participant',
                    component: () =>
                      import('/@front/views/app/auth/games/battleship/game/dashboards/participant/index.vue'),
                    redirect: { name: 'battleship-dashboard-participant-score' },
                    children: [
                      {
                        path: 'score',
                        name: 'battleship-dashboard-participant-score',
                        component: () =>
                          import('/@front/views/app/auth/games/battleship/game/dashboards/participant/score/index.vue'),
                      },
                      {
                        path: 'hints',
                        name: 'battleship-dashboard-participant-hints',
                        component: () =>
                          import('/@front/views/app/auth/games/battleship/game/dashboards/participant/hints/index.vue'),
                      },
                    ],
                  },
                  {
                    path: 'facilitator',
                    name: 'battleship-facilitator',
                    component: () => import('/@front/views/app/auth/games/battleship/game/facilitator/index.vue'),
                  },
                  {
                    path: 'facilitator/participants/:hash',
                    name: 'battleship-facilitator-participant',
                    component: () =>
                      import('/@front/views/app/auth/games/battleship/game/facilitator/participant/index.vue'),
                  },
                ],
              },
              {
                path: 'dashboard/game',
                name: 'battleship-dashboard-game',
                component: () => import('/@front/views/app/auth/games/battleship/game/dashboards/game/index.vue'),
                redirect: { name: 'battleship-dashboard-game-summary' },
                children: [
                  {
                    path: 'summary',
                    name: 'battleship-dashboard-game-summary',
                    component: () =>
                      import('/@front/views/app/auth/games/battleship/game/dashboards/game/summary/index.vue'),
                  },
                  {
                    path: 'facilitator-summary',
                    name: 'battleship-dashboard-game-facilitator-summary',
                    component: () =>
                      import(
                        '/@front/views/app/auth/games/battleship/game/dashboards/game/facilitator-summary/index.vue'
                      ),
                  },
                  {
                    path: 'players',
                    name: 'battleship-dashboard-game-players',
                    component: () =>
                      import('/@front/views/app/auth/games/battleship/game/dashboards/game/players/index.vue'),
                    children: [
                      {
                        path: ':hash/incoming',
                        name: 'battleship-dashboard-game-players-incoming',
                        component: () =>
                          import('/@front/views/app/auth/games/battleship/game/dashboards/game/incoming/index.vue'),
                      },
                      {
                        path: ':hash/outgoing',
                        name: 'battleship-dashboard-game-players-outgoing',
                        component: () =>
                          import('/@front/views/app/auth/games/battleship/game/dashboards/game/outgoing/index.vue'),
                      },
                    ],
                  },
                  {
                    path: 'incoming',
                    name: 'battleship-dashboard-game-incoming',
                    component: () =>
                      import('/@front/views/app/auth/games/battleship/game/dashboards/game/incoming/index.vue'),
                  },
                  {
                    path: 'outgoing',
                    name: 'battleship-dashboard-game-outgoing',
                    component: () =>
                      import('/@front/views/app/auth/games/battleship/game/dashboards/game/outgoing/index.vue'),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'room/finished',
        name: 'room-finished',
        component: () => import('/@front/views/app/auth/room/finished/index.vue'),
      },
    ],
  },
  {
    component: () => import('/@front/views/app/guest/index.vue'),
    path: '/',
    redirect: { name: 'room-select' },
    name: 'guest',
    children: [
      {
        path: 'room/select',
        name: 'room-select',
        component: () => import('/@front/views/app/guest/room/select/index.vue'),
      },
      {
        path: 'room/:code/login/',
        name: 'room-login',
        component: () => import('/@front/views/app/guest/room/login/index.vue'),
      },
      {
        path: 'room/:code/new-participant/',
        name: 'room-new-participant',
        component: () => import('/@front/views/app/guest/room/new-participant/index.vue'),
      },
      {
        path: 'room/:code/facilitator/',
        name: 'room-facilitator',
        component: () => import('/@front/views/app/guest/room/facilitator/index.vue'),
      },
      {
        path: 'room/:code/participants',
        name: 'room-participants',
        component: () => import('/@front/views/app/guest/room/participants/index.vue'),
      },
    ],
  },
]
