import '/@front:css/style.css'

import mitt from 'mitt'
import store from 'store'
import { App, createApp } from 'vue'

import { flits } from './plugins/flits'
import { t } from './plugins/i18n'
import api, { HttpError } from './shared/api'
import { registerComponents } from './shared/components'
import { tokenStorageKey } from './shared/constants'
import Logger from './shared/error-logger'
import { registerPlugins } from './shared/plugins'
import { router } from './shared/router'
import TeamupApp from './views/app/index.vue'

class Application {
  private logger!: Logger
  private app!: App

  constructor() {
    this.loadIcons()
    this.initVueInstance()
  }

  private initVueInstance() {
    const el = document.getElementById('teamup-app')
    if (!el) {
      return
    }

    const app = createApp(TeamupApp)

    app.use(router)

    registerComponents(app)
    registerPlugins(app)

    this.bindEventBus(app)
    this.initLogger(app)

    app.mount(el)

    this.app = app
  }

  private bindEventBus(app: App) {
    const emitter = mitt()

    app.config.globalProperties.$bus = {
      // @ts-ignore
      $on: (...args: any[]) => emitter.on(...args),
      // @ts-ignore
      $off: (...args: any[]) => emitter.off(...args),
      // @ts-ignore
      $emit: (...args: any[]) => emitter.emit(...args),
    }
  }

  private initLogger(app: App) {
    this.logger = new Logger()

    app.config.errorHandler = (err) => {
      if (err instanceof HttpError) {
        return this.processHttpError(err)
      } else {
        this.logger.addErrorRecord(err as Error)
      }

      if (!import.meta.env.prod) {
        throw err
      }
    }

    window.addEventListener('unhandledrejection', (rejection) => {
      ;(app.config.errorHandler as (...arg: any[]) => unknown)(rejection.reason, app, '')
    })
  }

  private processHttpError(err: HttpError) {
    switch (err.status) {
      case 401:
      case 403:
        this.processAuthorizationError()
        break
      case 422:
        this.processValidationError(err)
        break
      default:
        const data = err.data
        if (data.message) {
          flits.error(data.message)
        }

        this.logger.addErrorRecord(err)
    }
  }

  private processAuthorizationError() {
    store.remove(tokenStorageKey)
    const roomCode: string = window.$teamupState?.room.code

    delete api.defaults?.headers?.Authorization
    flits.error(t('Your session expired'))

    let route = router.resolve({ name: 'room-select' })
    if (roomCode) {
      route = router.resolve({ name: 'room-login', params: { code: roomCode } })
    }

    location.assign(route.href)
  }

  private processValidationError({ data }: any) {
    if (typeof data === 'string') {
      data = JSON.parse(data)
    }

    if (Array.isArray(data.errors)) {
      data.errors.forEach((messages: string[]) => {
        messages.forEach((message: string) => {
          flits.error(message)
        })
      })
    } else if (data.errors && Object.keys(data.errors).length > 0) {
      Object.keys(data.errors).forEach((key: string) => {
        data.errors[key].forEach((message: string) => {
          flits.error(message)
        })
      })
    } else if (data.message && data.message.length > 0) {
      flits.error(data.message)
    }
  }

  private async loadIcons() {
    const { default: icons } = await import('../img/icons.svg?raw')

    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = icons
    body.insertBefore(div, body.childNodes[0])
  }
}

export default new Application()
